import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ResultForm14 = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { amount, set, number, total } = location.state;

  const [formData, setFormData] = useState(null);

  const [numberSets, setNumberSets] = useState([]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    if (number && amount) {
      setFormData(location.state);
      let numbers = number.split(".");
      let amounts = amount.split(".").filter((a) => a !== "");
      console.log("amounts", amounts);
      let numbersWithAmount = numbers.flatMap((n) =>
        amounts.map((a) => ({ number: n, amount: a }))
      );

      let sets = [];
      for (let i = 0; i < Number(set); i++) {
        sets.push([]);
      }

      const shuffleNumber = shuffleArray(numbersWithAmount);

      shuffleNumber.forEach((combination, index) => {
        sets[index % Number(set)].push(combination);
      });

      setNumberSets(sets);
    }
  }, [amount, number, set]);

  const shareOnWhatsApp = (numbers, count) => {
    let message = numbers.map((n) => `${n.number} = ${n.amount} Rs`);
    message = message + `\n\nTotal: ${count}`;
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  const shareAllOnWhatsApp = () => {
    let messages = numberSets.map((box, i) => {
      let count = 0;
      let boxMessage = box
        .map((n) => {
          count += Number(n.amount);
          return `${n.number} = ${n.amount}`;
        })
        .join("\n");
      return `${boxMessage}\n\nTotal: ${count}`;
    });
    const combinedMessage = messages.join("\n\n\n");
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      combinedMessage
    )}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <div className="main-container">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          className="logout-button"
          onClick={() => {
            localStorage.removeItem("username");
            navigate("/");
          }}
        >
          Logout
        </button>
        <button
          className="logout-button"
          onClick={() => {
            localStorage.removeItem("username");
            navigate("/form", { state: { formData } });
          }}
        >
          Back
        </button>
      </div>
      <div className="result-page-container">
        <h2>Results</h2>
        <div className="boxes-container">
          {numberSets.length > 0 &&
            numberSets.map((sets, index) => {
              let count = 0;
              return (
                <div key={index} className="array-box">
                  <div className="box">
                    {sets.map((s) => {
                      count = count + Number(s.amount);
                      return (
                        <div>
                          <span>{s.number}</span>
                          <span className="separator"> = </span>
                          <span>{s.amount}</span>
                        </div>
                      );
                    })}
                    {total === "true" && <div>Total - {count}</div>}
                    <button
                      onClick={() => shareOnWhatsApp(sets, count)}
                      className="share-button"
                    >
                      Share
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
        <button onClick={shareAllOnWhatsApp} className="share-all-button">
          Share All
        </button>
      </div>
    </div>
  );
};

export default ResultForm14;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Form14 = ({ form14Values }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    number: "",
    total: false,
    set: "",
    amount: "",
    form: "form14",
  });

  useEffect(() => {
    if (form14Values) {
      setFormData(form14Values);
    }
  }, [form14Values]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    navigate("/result-form14", { state: formData });
    console.log(formData);
  };

  return (
    <div className="custom-form">
      <form onSubmit={handleFormSubmit}>
        <div className="form-group">
          <label htmlFor="number">Number</label>
          <textarea
            className="text-area-text"
            id="number"
            name="number"
            value={formData.number}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="total">Total</label>
          <select
            id="total"
            name="total"
            value={formData.total}
            onChange={handleInputChange}
            required
          >
            <option value={false}>False</option>
            <option value={true}>True</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="set">Set</label>
          <input
            type="number"
            id="set"
            name="set"
            value={formData.set}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="amount">Amount</label>
          <input
            type="text"
            id="amount"
            name="amount"
            value={formData.amount}
            onChange={handleInputChange}
            required
          />
        </div>

        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Form14;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Form.css";
import Form6 from "./Form6";
import Form14 from "./Form14";

const CustomForm = () => {
  const location = useLocation();
  console.log("location", location);
  const [activeForm, setActiveForm] = useState("formA");

  const [name, setName] = useState("");
  const [pana, setPana] = useState("");
  const [set, setSet] = useState("");
  const [separator, setSeparator] = useState(",");
  const [amount, setAmount] = useState("");
  const [text, setText] = useState("");

  const [form6Values, setForm6Values] = useState(null);
  const [form14Values, setForm14Values] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (location.state !== null) {
      setActiveForm(location.state.formData.form);

      if (location.state.formData.form === "form6") {
        setForm6Values(location.state.formData);
      } else if (location.state.formData.form === "form14") {
        setForm14Values(location.state.formData);
      } else {
        setName(location.state.formData.name);
        setPana(location.state.formData.pana);
        setSet(location.state.formData.set);
        setSeparator(location.state.formData.separator);
        setAmount(location.state.formData.amount);
        setText(location.state.formData.text);
      }
    }
  }, [location]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = {
      name,
      pana,
      set: parseInt(set, 10),
      separator,
      amount: parseInt(amount, 10),
      text,
      form: "formA",
    };
    navigate("/result", { state: formData });
  };

  const handleFormDoubleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      name,
      pana,
      set: parseInt(set, 10),
      separator,
      amount: parseInt(amount, 10),
      text,
      form: "Double",
    };
    navigate("/result-double", { state: formData });
  };

  const handleFormBSubmit = (event) => {
    event.preventDefault();
    const formData = {
      name,
      pana,
      set: parseInt(set, 10),
      separator,
      amount: parseInt(amount, 10),
      text,
      form: "formB",
    };
    navigate("/result-b", { state: formData });
  };

  const handleFormClick = (form) => {
    setActiveForm(form);
  };

  return (
    <div className="main-container">
      <button
        className="logout-button"
        onClick={() => {
          localStorage.removeItem("username");
          navigate("/");
        }}
      >
        Logout
      </button>
      <div className="custom-form-container">
        <div className="game-all-forms">
          <h2
            onClick={() => handleFormClick("formA")}
            className={
              activeForm === "formA"
                ? "game-form-button-active"
                : "game-form-button"
            }
          >
            Form 5-1A
          </h2>
          <h2
            onClick={() => handleFormClick("formB")}
            className={
              activeForm === "formB"
                ? "game-form-button-active"
                : "game-form-button"
            }
          >
            Form 5-1B
          </h2>

          <h2
            onClick={() => handleFormClick("form6")}
            className={
              activeForm === "form6"
                ? "game-form-button-active"
                : "game-form-button"
            }
          >
            Form 6
          </h2>
          <h2
            onClick={() => handleFormClick("form14")}
            className={
              activeForm === "form14"
                ? "game-form-button-active"
                : "game-form-button"
            }
          >
            Form 14
          </h2>
          <h2
            onClick={() => handleFormClick("Double")}
            className={
              activeForm === "Double"
                ? "game-form-button-active"
                : "game-form-button"
            }
          >
            Double
          </h2>
        </div>
        {activeForm === "formA" && (
          <div className="custom-form">
            {/* <h2>Form 5-1</h2> */}
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <label htmlFor="set">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="pana">Select Pana</label>
                <select
                  id="pana"
                  name="pana"
                  value={pana}
                  onChange={(e) => setPana(e.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option value="Pana">Pana</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="set">Set</label>
                <input
                  type="number"
                  id="set"
                  name="set"
                  value={set}
                  onChange={(e) => setSet(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="separator">Separator</label>
                <select
                  id="separator"
                  name="separator"
                  value={separator}
                  onChange={(e) => setSeparator(e.target.value)}
                  required
                >
                  <option value=",">Comma (,)</option>
                  <option value=".">Dot (.)</option>
                  <option value="+">Pluse (+)</option>
                  <option value="-">Minus (-)</option>
                  <option value="/">Back slash (/)</option>
                  <option value="=">Equal (=)</option>
                  <option value="@">commercial at (@)</option>
                  <option value="#">Hash (#)</option>
                  <option value="_">Hyphen (_)</option>
                  <option value='"'>Double Quotation Marks (")</option>
                  <option value=":">Colon (:)</option>
                  <option value="!">Exclamation mark (!)</option>
                  <option value="?">Question mark (?)</option>
                  <option value="'">Single Quotation Marks (')</option>
                  <option value="*">Asterisk (*)</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="amount">Amount</label>
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="text">Text (Use , or . to separate)</label>
                <textarea
                  className="text-area-text"
                  id="text"
                  name="text"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="submit-button">
                Submit
              </button>
            </form>
          </div>
        )}
        {activeForm === "formB" && (
          <div className="custom-form">
            {/* <h2>Form 5-1</h2> */}
            <form onSubmit={handleFormBSubmit}>
              <div className="form-group">
                <label htmlFor="set">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="pana">Select Pana</label>
                <select
                  id="pana"
                  name="pana"
                  value={pana}
                  onChange={(e) => setPana(e.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option value="Bracket">Bracket</option>
                  {/* <option value="Pana">Pana</option> */}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="set">Set</label>
                <input
                  type="number"
                  id="set"
                  name="set"
                  value={set}
                  onChange={(e) => setSet(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="separator">Separator</label>
                <select
                  id="separator"
                  name="separator"
                  value={separator}
                  onChange={(e) => setSeparator(e.target.value)}
                  required
                >
                  <option value=",">Comma (,)</option>
                  <option value=".">Dot (.)</option>
                  <option value="+">Pluse (+)</option>
                  <option value="-">Minus (-)</option>
                  <option value="/">Back slash (/)</option>
                  <option value="=">Equal (=)</option>
                  <option value="@">commercial at (@)</option>
                  <option value="#">Hash (#)</option>
                  <option value="_">Hyphen (_)</option>
                  <option value='"'>Double Quotation Marks (")</option>
                  <option value=":">Colon (:)</option>
                  <option value="!">Exclamation mark (!)</option>
                  <option value="?">Question mark (?)</option>
                  <option value="'">Single Quotation Marks (')</option>
                  <option value="*">Asterisk (*)</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="amount">Amount</label>
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="text">Text (Use , or . to separate)</label>
                <textarea
                  className="text-area-text"
                  id="text"
                  name="text"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="submit-button">
                Submit
              </button>
            </form>
          </div>
        )}

        {activeForm === "form6" && <Form6 form6Values={form6Values} />}
        {activeForm === "form14" && <Form14 form14Values={form14Values} />}
        {/* {activeForm === "Double" && <Double form14Values={form14Values} />} */}
        {activeForm === "Double" && (
          <div className="custom-form">
            {/* <h2>Form 5-1</h2> */}
            <form onSubmit={handleFormDoubleSubmit}>
              <div className="form-group">
                <label htmlFor="set">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="pana">Select Pana</label>
                <select
                  id="pana"
                  name="pana"
                  value={pana}
                  onChange={(e) => setPana(e.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option value="Pana">Pana</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="set">Set</label>
                <input
                  type="number"
                  id="set"
                  name="set"
                  value={set}
                  onChange={(e) => setSet(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="separator">Separator</label>
                <select
                  id="separator"
                  name="separator"
                  value={separator}
                  onChange={(e) => setSeparator(e.target.value)}
                  required
                >
                  <option value=",">Comma (,)</option>
                  <option value=".">Dot (.)</option>
                  <option value="+">Pluse (+)</option>
                  <option value="-">Minus (-)</option>
                  <option value="/">Back slash (/)</option>
                  <option value="=">Equal (=)</option>
                  <option value="@">commercial at (@)</option>
                  <option value="#">Hash (#)</option>
                  <option value="_">Hyphen (_)</option>
                  <option value='"'>Double Quotation Marks (")</option>
                  <option value=":">Colon (:)</option>
                  <option value="!">Exclamation mark (!)</option>
                  <option value="?">Question mark (?)</option>
                  <option value="'">Single Quotation Marks (')</option>
                  <option value="*">Asterisk (*)</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="amount">Amount</label>
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="text">Text (Use , or . to separate)</label>
                <textarea
                  className="text-area-text"
                  id="text"
                  name="text"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="submit-button">
                Submit
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomForm;
